.review-wrapper {
    width: 100%;
    /* background: linear-gradient(rgba(0,0,0,0.5) , rgba(0,0,0,0.5)) , url(../../Assets/cv-simplified.png); */
    background-color: #1D1D20;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

}

.review {
    max-width: 1400px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0px;
}

.review-t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 40px;
    color: white;
}

.review-t>h1 {
    font-size: 3rem;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.review-t>h4 {
    font-size: 30px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
}

.review-t>h4>span:nth-child(1) {
    color: #a2a2a8;
    margin-left: 5px;
    margin-right: 28px;
}

.review-t>h4>span:nth-child(2)>svg {
    color: aqua;
}

.review-t>h6 {
    font-size: 18px;
    color: #a2a2a8;
    font-weight: 400;
    line-height: 1.75rem;
    margin-bottom: 1.5rem;
}

.review-t>h6>span {
    color: white;
}

.review-b {
    width: 100%;
    min-height: 100%;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-bottom: 60px;
}

.review-b .swiper {
    width: 100%;
    height: 300px;
}

.review-b .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.review-b .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.review-b .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    display: none;
    visibility: hidden;
}

.reviewSlider {
    padding: 40px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.reviewSImgbox {
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin-bottom: 12px;
    border: 1px solid lightblue;
}

.reviewSImgbox>img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}

.reviewSTitle>h2 {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0;
}

.reviewSTitle>h3 {
    font-size: 18px;
    color: #a2a2a8;
    font-weight: 400;
    margin-bottom: 20px;
}

.reviewSdec {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.reviewSdec>p {
    font-size: 18px;
    color: white;
    width: 90%;
}