.impress-wrapper {
    width: 100%;
    background-color: rgba(221, 217, 217, 0.1);
    overflow: hidden;
}

.impress {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    padding: 0px 10px;
}

.impress-l {
    width: 50%;
    padding: 8rem 4rem 8rem 3rem;

}

.impress-l>h1 {
    font-weight: 600;
    line-height: 1.25;
    font-size: 3rem;
    margin-bottom: 0.75rem;
    color: #1d1d20;
    width: 90%;

}

.impress-l>h6 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 2.5rem;
    color: #6e6e76;
    font-weight: 400;
}

.impress-l>button {
    padding: 15px 25px;
    font-weight: 500;
    font-size: 18px;
}

.impress-r {
    width: 50%;
}

.cv-boxs {
    height: 700px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cv-box {
    width: 49%;
    height: 100%;
    overflow: hidden;
}

.CVimgbox {
    width: 100%;
    height: 300px;
    background-color: #1d1d20;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;

}
.CVimgbox>img {
    width: 100%;
    height: 100%;
}
.CVup{
    transform: translateY(-100px);
    animation: sliderup 30s linear infinite;
}
.CVdown{
    transform: translateY(-4400px);
    animation: sliderdown 30s linear infinite;
}

@keyframes sliderup {
    0% {
        transform: translateY(-100px);
    }

    100% {
        transform: translateY(calc(-300px * 9));
    }
}
@keyframes sliderdown {
    0% {
        transform: translateY(-4400px);
    }

    100% {
        transform: translateY(calc(-300px * 9));
    }
}


