.footer-wrapper{
    background-color: white;
    width: 100%;
}
.footer{
    max-width: 1140px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footer-boxs{
    display: flex;
    justify-content: center;
    margin: 0px 35px;
    padding: 0px 20px;
    width: 100%;
    margin-left: 80px;
}
.footer-box{
    margin: 0px 10px;
    width: 25%;
}
.footer-box>h2{
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
    color: #1d1d20;
    margin-bottom: 24px;
}
.footer-box>ul{
    list-style-type: none;
}
.footer-box>ul>li{
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 1.5rem;
    font-weight: 300;
    color: #6e6e67;
    font-family: 'Poppins', sans-serif;
    transition: 0.3s ease;
}
.footer-box>ul>li:hover{
    cursor: pointer;
    color: #333;
}
.copyright{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 80px;
}
.copyright>h2{
    font-size: 1rem;
    line-height: 1.5rem;
    color: #6e6e67;
    font-weight: 500;
}