.main-wrapper {
    width: 100%;
    background-color: rgba(221, 217, 217, 0.1);
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.main {
    max-width: 1140px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px;
}

.maintitle {
    margin-bottom: 10px;
    display: flex;
}

/* .maintitle .text {
    font-size: 60px;
    font-weight: 500;
    line-height: 1.5;
    color: #1D1D20;
}

.maintitle .autotext {
    margin-left: 15px;
    height: 90px;
    line-height: 90px;
    overflow: hidden;
    background-color: #1D1D20;
}

.autotext>li {
    font-size: 60px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--purple-dark);
    position: relative;
    top: 0;
    animation: type 12s steps(4) infinite;
}

@keyframes type {
    100% {
        top: -360px;
    }
}
.autotext>li>span{
    position: relative;
    margin: 5px 0;
    line-height: 90px;
}
.autotext>li>span::after{
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #111;
    border-left: 2px solid #fafafa;
    animation: typing 3s steps(12) infinite;
}
@keyframes typing {
    40% , 60%{
        left: calc(100% + 30px);
    }
    100%{
        left: 0;
    }
    
} */

.maintitle>h1 {
    font-size: 60px;
    font-weight: 500;
    line-height: 1.5;
    color: #1D1D20;
}

.maindec {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.maindec>h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: .025em;
    color: #6e6e76;
    font-weight: 400;
    width: 80%;
}

.main-btn {
    padding-bottom: 1rem;
}

.main-btn>button {
    padding: 20px 40px;
    font-weight: 500;
    font-size: 20px;
}

.main-swiper {
    max-width: 1600px;
    width: 100%;
    min-height: 100%;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.main-swiper .swiper {
    width: 100%;
    height: 600px;
    overflow: visible;
    user-select: none;

}

.main-swiper .swiper-slide:hover {
    transform: translateY(-10px) scale(1.05);

}

.main-swiper .swiper-slide:hover .animatebtn {

    transform: scale(1);
    transform-origin: right;

}

.main-swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 11px 20px 12px 0px #ddd;
    overflow: hidden;
    user-select: none;
    position: relative;
    transition: 0.5s ease;
}

.main-swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-swiper .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    display: none;
    visibility: hidden;
}

.swiperbtn {
    position: absolute;
    bottom: 50px;
}

.animatebtn {
    position: relative;
    padding: 15px 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 2px;
    border: 2px solid #0f0;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    background-color: #1D1D20;
    -webkit-box-reflect: below 0px linear-gradient(transparent, #0002);
    transition: 0.5s ease;
    transition-delay: 0s;
    transform: scale(0);
}

.animatebtn>span {
    position: relative;
    z-index: 20;
}

.animatebtn:hover,
.animatebtn>span:hover {
    border: 1px solid #0f0;
    transition-delay: 0.7s;
    color: black;
    cursor: pointer;
}

.animatebtn::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    transform: translateY(-50%);
    background-color: #0f0;
    left: -20px;
    top: 50%;
    box-shadow: 5px -8px 0px #0f0, 5px 8px 0px #0f0;
    transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
    transition-delay: 0.7s, 0.2s, 0s, 0s;
}

.animatebtn:hover::before {
    width: 60%;
    height: 100%;
    left: -2px;
    box-shadow: 5px 0px 0px #0f0, 5px 0px 0px #0f0;
    transition-delay: 0s, 0.2s, 0.7s, 0.7s;
}

.animatebtn::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    transform: translateY(-50%);
    background-color: #0f0;
    right: -20px;
    top: 50%;
    box-shadow: -5px -8px 0px #0f0, -5px 8px 0px #0f0;
    transition: width 0.5s, right 0.5s, height 0.5s, box-shadow 0.5s;
    transition-delay: 0.7s, 0.2s, 0s, 0s;

}

.animatebtn:hover::after {
    width: 60%;
    height: 100%;
    right: -2px;
    box-shadow: -5px 0px 0px #0f0, -5px 0px 0px #0f0;
    transition-delay: 0s, 0.2s, 0.7s, 0.7s;

}