*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    color: #1d1d20;
    line-height: inherit;
    font-family: 'Poppins', sans-serif;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    color: inherit;
}
:root{
    --color-brand-50: #f9f6fe;
    --color-brand-100: #e2d8fb;
    --purple-sslight: #b69df4;
    --purple-slight: #8a61ee;
    --purple-light: #5d25e7;
    --purple-dark: #4314b6;
    --color-brand-rgb: 67,20,182;
    --color-background: #fafafa;
}
.btn{
    background-color: var(--purple-dark);
    color: white;
    border: none;
    outline: none;
    padding: 7px 15px;
    border-radius: 6px;
    font-weight:500;
    letter-spacing: 1px;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 0.3s ease;
}
.btn:hover{
    background-color: var(--purple-light);
    cursor: pointer;

}