.resume-wrapper {
    width: 100%;
    background-color: rgba(221, 217, 217, 0.1);
}

.resume {
    max-width: 1140px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 110px 100px;
}

.resume>h1 {
    font-size: 3rem;
    line-height: 1;
    font-weight: 500;
    color: #1D1D20;
    margin-bottom: 10px;
}

.resume>h3 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #6e6e76;
    font-weight: 400;
    width: 60%;
}

.ResumeTemplate {
    width: 100%;
}

.ResumeTemplateBoxs {
    position: relative;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 1.25rem;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.ResumeTemplateBox {
    width: 360px;
    height: 460px;
    background-color: var(--purple-dark);
    margin: 20px 10px;
    border-radius: 10px;
}

.ResumeNext {
    max-width: 1200px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.ResumeNext>div{
    width: 50%;
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
}
.ResumeNext>div>p{
    font-size: 16px;
    color: black;
    font-weight: 600;
    cursor: pointer;
}
.ResumeNext>div>p>span{
    margin-left: 20px;
    font-weight: 400;
}
.ResumeNext>div>p>span:hover{
    border: 1px solid var(--purple-dark);
}