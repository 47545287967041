.navbar-wrapper {
    width: 100%;
    background-color: rgba(221, 217, 217, 0.1);
    transition: 0.5s ease;
    z-index: 100;
}

.navbar-visible {
    background-color: white;
    position: fixed;
    box-shadow: 0px 0px 10px #6E6E76;
}
.navbar-v{
    background-color: white;

}
.navbar {
    max-width: 1400px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    width: 100%;
    position: relative;
}
.navbar-logo,
.navbar-logo>a {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: left;
}
.navbar-logo>h1,
.navbar-logo>a>h1 {
    font-size: 20px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--purple-dark);

}
.navbar-logo>span,
.navbar-logo>a>span {
    font-size: 12px;
    margin-left: -2px;
    font-weight: 700;
}

.navbar-menu {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.navbar-menu>ul {
    display: flex;
    list-style-type: none;
}

.navbar-menu>ul>li {
    font-size: 18px;
    color: #6E6E76;
    margin: 0px 40px;
    line-height: 1.3;
    letter-spacing: normal;
    cursor: pointer;
}

.navbar-r {
    width: 20%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.navbar-r>button {
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.dropDown{
    position: absolute;
    background-color: white;
    padding: 10px;
    left: 38%;
    transition: 0.3s linear;
    top: 62px;
    opacity: 0;
}
 .dropDown>ul{
    list-style-type: none;
    
}
.dropDown>ul>li{
    cursor: pointer;
    position: relative;

}
.dropDownVisible{
    background-color: white;
    opacity: 1;
}
.minidropdown1 ,
.minidropdown2 ,
.minidropdown3{
    list-style-type: none;
    position: absolute;
    background-color: white;
    padding: 10px;
    transform: translateX(103px);
    opacity: 0;
    transition: 0.2s ease;
}
.minidropdown1{
    top: -10px;
}
.minidropdown2{
    top: -10px;
}
.minidropdown3{
    top: -10px;
}
.minidrop1:hover .minidropdown1 ,
.minidrop2:hover .minidropdown2 ,
.minidrop3:hover .minidropdown3{
    opacity: 1;
} 
