.work {
    width: 100%;
    background-color: transparent;
}

.work-wrapper {
    max-width: 1140px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 20px;
}

.wrok-title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 40px;
}

.wrok-title>h1 {
    font-size: 45px;
    font-weight: 500;
    color: #1d1d20;
    line-height: 1.5;
}

.work-t {
    margin-bottom: 10px;
}

.work-boxs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    padding: 10px;

}

.work-box {
    width: 30%;
    height: 150px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s linear;
}

.work-cricle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid #e4e4e7;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    box-shadow: 0 4px 6px -1px #e4e4e7, 0 2px 4px -2px #e4e4e7;
    font-size: 15px;
}

.work-details {
    color: #6e6e76;
    width: 80%;
    margin-left: 20px;
    margin-top: 10px;
}

.work-details>h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 400;
    margin-bottom: 10px;
    transition: 0.3s linear;
}

.work-details>h6 {
    font-size: 18px;
    font-weight: 400;
    width: 90%;
}
.work-box:hover .work-details>h3{
    color: var(--purple-dark);
    cursor: pointer;
}
.work-b{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.work-b>img{    
    width: 70%;
    height: 100%;
}